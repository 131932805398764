export default [
  {
    type: "mc",
    question: "ใครคือบิดาแห่งเศรษฐศาสตร์",
    choices: [
      "จอห์น เมนาร์ด เคนส์",
      "อดัม สมิธ",
      "อัลเฟรด มาร์แชลล์",
      "พอล แซมมวลสัน"
    ],
    answer: 1,
    answerExplanation: [
      "เคนส์ได้รับการยกย่องว่าเป็นบิดาแห่งเศรษฐศาสตร์มหภาค เนื่องจากเขาเป็นผู้ริเริ่มเศรษฐศาสตร์มหภาคเป็นคนแรก",
      "อดัม สมิธ เป็นนักเศรษฐศาสตร์ผู้บุกเบิก ได้รับการยากย่องให้เป็นบิดาทางเศรษฐศาสตร์เนื่องจากเขียนหนังสือ การสอบสวนธรรมชาติและสาเหตุแห่งความมั่งคั่งของประชาชาติ (An Inquiry Into the Nature and Causes of the Wealth of Nations) ซึ่งเป็นตำราทางเศรษฐศาสตร์เล่มแรกของโลก",
      "มาร์แชลล์ได้รับการยกย่องว่าเป็นบิดาแห่งเศรษฐศาสตร์จุลภาค เนื่องจากเขียนหนังสือ บัญญัติ 10 ประการทางเศรษฐศาสตร์ (Principles of Economics) ซึ่งเป็นตำราเศรษฐศาสตร์จุลภาคเล่มแรกของโลก",
      "แซมมวลสัน เป็นนักเศรษฐศาสตร์ชาวอเมริกันที่นิยามความหายของคำว่าเศรษฐศาสตร์"
    ],
    topics: ["ประวัติความเป็นมา"],
    bookmark: false
  },
  {
    type: 'mc',
    question: 'หน่วยเศรษฐกิจใดในระบบเศรษฐกิจที่นำปัจจัยการผลิตมาผลิตสินค้าและบริการ',
    choices: ['รัฐบาล', 'ครัวเรือน', 'ธนาคาร', 'ธุรกิจ'],
    answer: 3,
    answerExplanation: [' รัฐบาลเป็นผู้บริโภคและเจ้าของปัจจัยการผลิต แต่ไม่ใช่ผู้ที่นำปัจจัยการผลิตมาทำเป็นสินค้าและบริการ',
    'ครัวเรือนเป็นหนึ่งในปัจจัยการผลิตผ่านการเป็นแรงงาน และเป็นหน่วยที่จ่ายเงินเพื่อให้ได้สินค้าและบริการจากหน่วยธุรกิจมา เช่น นาย ก (จากหน่วบครัวเรือน) ซื้อของจากร้านสะดวกซื้อ (หน่วยธุรกิจ) และนาย ก สามารถรับจ้างเป็นพนักงานในร้านสะดวกซื้อได้',
    'ธนาคารเป็นสถาบันทางการเงิน ไม่นับว่าเป็นหน่วยเศรษฐกิจ เนื่องจากหน่วยเศรษฐกิจคือเพียง 3 หน่วยคือ ครัวเรือน ธุรกิจ และรัฐบาลเท่านั้น',
    'ธุรกิจคือบุคคลหรือกลุ่มคนที่นำปัจจัยการผลิตมาผลิตสินค้าและบริการ แล้วนำไปขายให้กับครัวเรือน ธุรกิจด้วยกันเอง และรัฐบาล เช่น ร้านสับปะรด (หน่วยธุรกิจ) ขายคอมพิวเตอร์ให้กับนาย ก (หน่วยครัวเรือน) หรือขายให้กับรัฐบาล (หน่วยรัฐบาล) นำไปใช้ในสำนักงาน เป็นต้น'],
    topics: ['หน่วยเศรษฐกิจ'],
    bookmark: false
    },
    {
        type: 'mc',
        question: 'ระบบเศรษฐกิจแบบใดที่มีการแข่งขันด้านคุณภาพ ประสิทธิภาพ และการบริการอย่างเสรี โดยมีกฎหมายรับรอง',
        choices: ['ระบบสังคมนิยม', 'ระบบเศรษฐกิจแบบผสม', 'ระบบทุนนิยมหรือเสรีนิยม', 'ระบบคอมมิวนิสต์'],
        answer: 2,
        answerExplanation: ['ระบบสังคมนิยมมีรัฐบาลเป็นเจ้าของปัจจัยการผลิต จัดสรรงบประมาณมาทำธุรกิจ ซึ่งมักจะขาดทุนและคุณภาพต่ำ',
        'ระบบเศรษฐกิจแบบผสมคือการนำข้อดีของระบบทุนนิยมกับสังคมนิยมมาผสมกัน ถึงแม้ว่ารัฐบาลไม่จำกัดเรื่องการเป็นเจ้าของปัจจัยการผลิต แต่มีการจำกัดกลไกราคา ทำให้การแข่งขันยังถูกจำกัดเสรีภาพอยู่บ้าง',
        'ระบบทุนนิยมหรือเสรีนิยม เอกชนมีเสรีภาพในการเป็นเจ้าของปัจจัยการผลิตเหมือนระบบผสม ทำให้เกิดการแข่งขันในตลาด ราคาสินค้าเป็นไปตามกลไกตลาด ส่งผลให้เอกชนต้องแข่งขันกันผลิตสินค้าให้มีประสิทธิภาพและราคาถูก',
        'ระบบคอมมิวนิสต์เป็นเจ้าของปัจจัยการผลิตและทำกิจกรรมทางเศรษฐกิจเองทั้งหมด ทำให้ไม่มีคู่แข่ง ส่งผลให้มีสินค้าและบริการน้อยและสินค้าด้อยคุณภาพ'],
        topics: ['ระบบเศรษฐกิจ'],
        bookmark: false
    },
    {
        type: 'mc',
        question: 'ข้อใดไม่ใช่ข้อเสียของระบบทุนนิยม',
        choices: ['รายได้ส่วนใหญ่ตกแก่นายทุนและการกระจายรายได้ไม่ดี', 'นายทุนรวมตัวกันขายสินค้าราคาแพง', 'เกิดการใช้ทรัพยากรธรรมชาติอย่างฟุ่มเฟือย', 'เอกชนไม่มีเสรีภาพทางเศรษฐกิจ'],
        answer: 3,
        answerExplanation: ['ในระบบทุนนิยม นายทุนมีรายได้มหาศาลมาจากการจ่ายค่าจ้างราคาต่ำ เพื่อนำมาลงทุนขยายธุรกิจต่อไป เช่น นาย ข เปิดร้านขายขนมปัง ในฐานะเจ้าของกิจการ นาย ข ต้องการกำไรที่สูงที่สุด จึงทำให้แรงงานของตัวเองได้รับค่าจ้างในราคาที่ต่ำลง เพื่อนำเงินส่วนนั้นมาเป็นกำไรของตัวเอง เมื่อแรงงานได้เงินน้อยลง แรงงานจึงมีเงินไปใช้จ่ายในชีวิตประจำวันน้อยลง ทำให้เงินหมุนเวียนในระบบน้อยลง หรือเรียกว่าการกระจายรายได้ไม่ดี',
        ' ถ้าสินค้าในตลาดนั้นมีผู้ผลิตน้อยราย ผู้ผลิตสินค้าอาจจะรวมตัวกันมาพูดคุยกันว่าจะขึ้นราคาสินค้านั้นพร้อมกัน ทำให้สินค้ามีราคาแพง เช่น ผู้ประกอบการบริษัทมือถือในประเทศ A มีเพียง 3 ราย แล้วทั้ง 3 คนตกลงกันว่าจะขึ้นราคาค่าโทรศัทพ์พร้อมกัน ทำให้ผู้บริโภทต้องจ่ายค่าโทรศัพท์แพงขึ้น เพราะไม่จำเป็นต้องแข่งกันผู้ประกอบการรายอื่นที่ทำให้ราคาค่าโทรศัทพ์ถูกลงจนลูกค้าหันมาใช้ผลิตภัณฑ์ของบริษัทนั้น',
        'เนื่องจากระบบทุนนิยมเอื้อให้ผู้ผลิตสามารถนำปัจจัยการผลิตมาผลิตสินค้าได้อย่างเสรี ทำให้ผู้ประกอบการนำปัจจัยการผลิตมาใช้มากขึ้น ส่งผลให้เกิดการใช้ทรัพยากรอย่างฟุ่มเฟือยและสิ่งแวดล้อมเสื่อมโทรม เช่น ผู้ประกอบการเฟอร์นิเจอร์หลายรายต้องการผลิตโซฟาไม้แข่งกัน ผู้ผลิตจำเป็นต้องตัดต้นไม้ เพื่อนำมาทำโซฟาไม้สำหรับจำหน่าย ทำให้สูญเสียต้นไม้และสิ่งแวดล้อมเสื่อมโทรมลง',
        'ระบบทุนนิยมให้เสรีภาพกับเอกชนอย่างเต็มที่ แต่ระบบคอมมิวนิสต์จะจำกัดเสรีภาพทางเศรษฐกิจของเอกชน เนื่องจากรัฐบาลเป็นผู้ดำเนินกิจกรรมทางเศรษฐกิจทั้งหมดเอง'],
        topics: ['ระบบเศรษฐกิจ'],
        bookmark: false
        },
        {
          type: 'mc',
          question: 'ประเทศไทยใช้ระบบเศรษฐกิจแบบใด',
          choices: ['ระบบคอมมิวนิสต์', 'ระบบทุนนิยม', 'ระบบเศรษฐกิจแบบผสม', 'ระบบสังคมนิยม'],
          answer: 2,
          answerExplanation: ['ระบบคอมมิวนิสต์ไม่ให้เอกชนรับผิดชอบด้านเศรษฐกิจเลย ขณะที่ประเทศไทยให้เอกชนมีส่วนร่วมรับผิดชอบด้านเศรษฐกิจ',
          'ในระบบทุนนิยม รัฐบาลจะไม่เข้าไปแทรกแซงระบบเศรษฐกิจ ขณะที่ในประเทศไทย รับบาลยังเข้ามาแทรกแซงเศรษฐกิจเมื่อจำเป็น',
          'ในประเทศไทย ทั้งรัฐบาลและเอกชนสามารถเป็นเจ้าของปัจจัยการผลิตได้ โดยเอกชนมีแรงจูงในในการผลิตแบบระบบทุนนิยม แต่มีการควบคุมจากรัฐบาล เพื่อแก้ปัญหาทางเศรษฐกิจเหมือนระบบสังคมนิยม',
          'ในระบบสังคมนิยมเอกชนจะถูกจำกัดเสรีภาพด้านเศรษฐกิจบางส่วน  ขณะที่ประเทศไทยให้เอกชนมีส่วนร่วมรับผิดชอบด้านเศรษฐกิจเป็นส่วนใหญ่ คล้ายระบบทุนนิยม'],
          topics: ['ระบบเศรษฐกิจ'],
          bookmark: false
          },
          
        
        
];
