import React, {useEffect} from "react";

import styles from "./Content3Page.module.css";

export default function ContentPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={styles.contentContainer}>
      <h1>สรุปเนื้อหา เศรษฐศาสตร์</h1>
      <h2>ระบบเศรษฐกิจ</h2>
      <p>
        เคยสงสัยไหมว่าทำไมเกิดสงครามเย็น?
        <br></br><br></br>ทำไมอเมริกาบอกว่าระบบเศรษฐกิจของพวกเขาเสรีกว่าของจีน
        แล้วมั่นใจว่าระบบเศรษฐกิจของตัวเองดีที่สุดในโลก? 
        <br></br><br></br>ระบบเศรษฐกิจ หมายถึง
        ลักษณะการดำเนินกิจกรรมทางเศรษฐกิจของแต่ละพื้นที่
        ในแต่ละสังคมบนโลกใบนี้มีวิธีการดำเนินกิจกรรมทางเศรษฐกิจที่แตกต่างกันไปตามวัฒนธรรม
        ประเพณี ความเชื่อของผู้คน สภาพแวดล้อม ฯลฯ
        แต่ทุกพื้นมีเป้าหมายเดียวกันคือใช้ระบบใดก็ได้ให้ประชาชนของตัวเองกินดี
        อยู่ดี มีความสุข เกิดความเท่าเทียมในสังคม
        <br></br><br></br>ระบบเศรษฐกิจทั่วโลกสามารถแบ่งออกได้เป็น 4 กลุ่มใหญ่ๆ 
        <br></br><br></br>‘ระบบคอมมิวนิสต์’
        คือระบบที่รัฐบาลเป็นเจ้าของทรัพยากรและปัจจัยการผลิตทั้งหมด
        รัฐจึงเป็นผู้ที่จัดสรรทรัพยากรให้ทุกคน เป็นคนกำหนดว่าต้องผลิตอะไร ทำไม
        เพื่อใคร ทำให้กลไกราคาไม่มีบทบาทในการแก้ไขปัญหาเศรษฐกิจ
        <br></br><br></br>ข้อดีคือระบบนี้ช่วยลดปัญหาความเหลื่อมล้ำทางเศรษฐกิจ
        เนื่องจากทุกคนผลิตตามที่รัฐบาลสั่ง เพื่อส่งเข้าระบบส่วนกลางของรัฐ
        หลังจากนั้นรัฐจะเป็นผู้พิจารณาเองว่าจะจัดสรรทรัพยากรให้กับประชาชนอย่างเท่าเทียมกัน
        <br></br><br></br>เช่น เราถูกรัฐสั่งให้ปลูกข้าว เมื่อปลูกข้าวเสร็จแล้ว
        เราต้องส่งข้าวทั้งหมดที่เราปลูกให้กับรัฐบาล
        จากนั้นรัฐบาลจะเป็นคนแบ่งข้าวมาให้เราเองว่าเราสมควรได้รับข้าวในปริมาณเท่าไหร่ที่จะได้รับข้าวในปริมาณที่เท่าเทียมกับคนอื่น
        ทำให้ตรงนี้กลายเป็นข้อเสียของระบบคอมมิวนิสต์
        คือทุกคนไม่มีเสรีภาพเลือกได้ว่าอยากผลิตหรือบริโภคอะไรเลย
        ต้องรอคำสั่งหรือรอรัฐบาลมอบทุกอย่างให้
        พอไม่มีทรัพยากรก็ไปผลิตอะไรแข่งขันกับรัฐไม่ได้
        รัฐบาลไม่มีคู่แข่งก็ไม่จำเป็นต้องผลิตสินค้าดี ๆ ไปแข่งกับใคร
        <br></br><br></br>‘ระบบสังคมนิยม’ คล้ายกับระบบคอมมิวนิสต์
        แต่รัฐบาลจะควบคุมระบบเศรษฐกิจและกิจการที่มีความสำคัญกับระบบเศษฐกิจมาก ๆ
        เช่น เหมืองแร่ น้ำมัน น้ำประปา ไฟฟ้า สาธารณูปโภค
        พร้อมกับปล่อยให้เอกชนสามารถมีกรรมสิทธิ์ครอบครองทรัพย์สินอยู่บ้าง
        ทำให้มีข้อดี-ข้อเสียคล้ายกับระบบคอมมิวนิสต์ 
        <br></br><br></br>‘ระบบทุนนิยม’
        ระบบที่ให้เสรีภาพกับภาคเอกชนในการผลิตอย่างเสรี
        โดยที่รัฐบาลจะไม่เข้ามาเกี่ยวข้องในกิจกรรมทางเศรษฐกิจ
        รัฐมีบทบาทเพียงออกกฎหมายควบคุม เพื่อรักษาความเรียบร้อยเท่านั้น
        ทำให้ราคาสินค้าเป็นไปตามกลไกราคาหรือตามะรรมชาติที่มันควรจะเป็น
        <br></br><br></br> ข้อดีของระบบทุนนิยมคือเมื่อทุกคนอยากจะผลิตอะไรก็ได้
        ถ้ามีคนเลือกผลิตสินค้าเหมือนกัน
        ก็จะต้องมาแข่งกันว่าสินค้าของใครคุณภาพดีกว่า ราคาถูกกว่า
        เพราะผู้บริโภคจะเลือกซื้อสินค้าและบริการที่ดีที่สุด
        ทำให้สินค้าในตลาดมีคุณภาพดีและราคาถูก 
        <br></br><br></br>เช่น โรงหนัง A เปิดโรงหนังมากว่า
        10 ปีแล้ว อยู่ดี ๆ ก็มีโรงหนัง B มาเปิดแข่งขันในตลาด แล้วโรงหนัง B
        มีเบาะนุ่มกว่า แอร์เย็นสบายกว่า ป๊อปคอร์นและค่าตั๋วถูกกว่าโรงหนัง A
        ทุกคนก็จะมาดูหนังที่โรงหนัง B เจ้าของโรงหนัง A
        ก็ต้องไปคิดแล้วว่าจะทำยังไงให้คนกลับมาดูหนังกับเขา
        จะเปลี่ยนเบาะให้นอนได้ พร้อมกับออกโปรโมชันลดราคาค่าตั๋วทุกวันพุธดีไหม
        <br></br><br></br>แต่ถ้าประเทศแบบระบบคอมมิวนิสต์หรือสังคมนิยม ทั้งประเทศมีแค่โรงหนัง A
        ของรัฐบาล ไม่มีการแข่งขันเลย เบาะเก่ายังไงก็เก่าต่อไป
        มีกลิ่นบุหรี่ก็ไม่ต้องแก้ไข เพราะสุดท้ายถ้าคนอยากดูหนัง
        สุดท้ายไม่มีทางเลือกก็ต้องเข้าโรงหนัง A อยู่ดี 
        <br></br><br></br>ทว่า
        ทุนนิยมก็มีข้อเสียของมันเหมือนกัน
        โดยเฉพาะเรื่องการกระจายรายได้ที่ไม่เท่าเทียม
        <br></br><br></br>ลองคิดดูว่าเราเป็นพนักงานแคชเชียร์ในโรงหนังตัวเล็ก ๆ
        คนหนึ่งที่ทำงานรับเงินจากคนอื่นแทบตาย
        แต่เงินที่เรารับมาใส่มือก็เป็นเงินของเจ้าของกิจการหมด
        สุดท้ายเงินก้อนนั้นถูกจัดสรรมาเป็นเงินเดือนของเราแค่นิดเดียว
        ทำงานหนักก็ได้เงินเดือนเท่าเดิม
        แบบนี้ก็เหมือนความไม่เท่าเทียมรูปแบบหนึ่ง 
        <br></br><br></br>คอมมิวนิสต์ก็สุดโต่งแบบหนึ่ง
        ทุนนิยมก็สุดโต่งอีกแบบหนึ่ง
        จึงมีอีกหนึ่งระบบที่นำข้อดีของทั้งสองระบบมายำรวมกันในระบบเดียวก็คือ
        ‘ระบบเศรษฐกิจแบบผสม’
        <br></br><br></br>ระบบเศรษฐกิจแบบผสมคือระบบที่อยู่กึ่งกลางระหว่างสังคมนิยมและทุนนิยม
        ทั้งรัฐบาลและเอกชนเข้ามามีบทบาทในการแก้ปัญหาเศรษฐกิจทั้งคู่
        เอกชนยังมีเสรีภาพในการดำเนินกิจกรรมทางเศรษฐกิจเหมือนเดิม
        มีด้านที่เหมือสังคมนิยมเพียงด้านเดียวคือรัฐบาลจะเข้ามาลงทุนในกิจการที่ลงทุนยาก
        ลงทุนแล้วไม่คุ้มค่า เสี่ยงกับการขาดทุน แต่ทุกคนจำเป็นต้องใช้ เช่น ไฟฟ้า
        น้ำประปา เป็นต้น 
        <br></br><br></br>สมมติเราให้เอกชนเข้ามาบริหารงาน
        ปล่อยให้ค่าไฟไปตามกลไกราคาตลาด
        เราอาจจะจ่ายค่าไฟแพงกว่าที่เราจ่ายอยู่ทุกวันนี้ไม่รู้กี่เท่า
        แต่ที่เราจ่ายค่าไฟราคาถูกทุกวันนี้เพราะรัฐบาลควบคุมราคาและการผลิตเอาไว้
        เป็นต้น 
        <br></br><br></br>ระบบผสมก็ไม่ได้มีแต่ข้อดี
        เพราะมันก็คือการนำข้อเสียของทั้งสองระบบมายำรวมกันเหมือนกัน เช่น
        เกิดความเหลื่อมล้ำแบบทุนนิยม
        หรือรัฐบาลอาจจะเข้ามาแทรกแซงแบบสังคมนิยมก็ได้
      </p>
      <h2>อ้างอิง</h2>
      <ul>
        <li>
          <a
            href="http://sagehouse.igetweb.com/articles/517671/%E0%B8%AB%E0%B8%99%E0%B9%88%E0%B8%A7%E0%B8%A2%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99%E0%B8%A3%E0%B8%B9%E0%B9%89%E0%B8%97%E0%B8%B5%E0%B9%881%E0%B9%80%E0%B8%A8%E0%B8%A3%E0%B8%A9%E0%B8%90%E0%B8%A8%E0%B8%B2%E0%B8%AA%E0%B8%95%E0%B8%A3%E0%B9%8C%E0%B9%80%E0%B8%9A%E0%B8%B7%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%95%E0%B9%89%E0%B8%99.html
"
          >
            แหล่งอ้างอิงที่ 1
          </a>
        </li>
        <li>
          <a
            href="https://my.dek-d.com/anything-blabla/writer/viewlongc.php?id=731085&chapter=3"
          >
            แหล่งอ้างอิงที่ 2
          </a>
        </li>
        <li>
          <a
            href="https://social.mwit.ac.th/files/58_2_doc_s30105_2.pdf"
          >
            แหล่งอ้างอิงที่ 3
          </a>
        </li>
      </ul>
      <hr />
    </div>
  );
}
